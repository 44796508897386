<template>
  <div class="login" :style="{backgroundImage: 'url(' + background.img_url + ')'}">
    <div class="login-info" v-drag :style="login_p_t!=0 && login_p_l!=0? 'left:'+login_p_l+';top:'+login_p_t: ''">
      <el-card dis-hover style="padding:20px;">
        <div class="align-center">
          <span class="m-f-color fs-56">{{ brand_name }}</span>
        </div>
        <div class="mt-50">
          <el-form ref="formInline" :model="formInline" :rules="ruleInline">
            <el-form-item prop="email">
              <el-input suffix-icon="el-icon-user" type="text" size="large" v-model="formInline.email" placeholder="Email">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input suffix-icon="el-icon-lock" type="password" size="large" v-model="formInline.password" placeholder="Password" @keyup.enter.native="enterToLogin('formInline')">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="large" @click="handleSubmit('formInline')" class="submit-button" :loading="login_loading">Sign in</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div style="display:flex;justify-content: space-between;font-size:13px;">
            <span class="forgot">
              <router-link to="/forgot"><span>Forgot password？</span></router-link>
            </span>
            <span>
              <router-link to="/signUp"><span class="m-f-color">Sign up</span></router-link>
            </span>
        </div>
      </el-card>
    </div>
  </div>


</template>

<script>
import * as loginUtil from '@/utils/login'
import {apiLogin} from '@/api/user/login'


export default {
  name: "Login",
  //自定义指令
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el;  // 获取当前元素

        oDiv.onmousedown = (e) => {
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;

          let clientW = document.body.clientWidth;
          let clientH = document.body.clientHeight;

          let w = oDiv.offsetWidth;
          let h = oDiv.offsetHeight;

          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;

            if (left < 0) {
              left = 0;
            }

            if (top < 0) {
              top = 0;
            }

            if (left + w > clientW) {
              left = clientW - w;
            }

            if (top + h > clientH) {
              top = clientH - h;
            }

            oDiv.style.left = left + 'px';
            oDiv.style.top = top + 'px';

            localStorage.setItem('login_p_l', left + 'px');
            localStorage.setItem('login_p_t', top + 'px');
          };

          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          }
        }
      }
    }
  },
  data() {
    /* 邮箱 */
    var emailFormatValid = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg =
          /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
        const email = reg.test(value)
        if (!email) {
          callback(new Error("This email is invalid. Make sure it's written like example@email.com"))
        } else {
          callback()
        }
      }
    }
    return {
      background: {
        img_url: 'https://cn.bing.com/th?id=OHR.GlacierBayOtter_ZH-CN6065209551_1920x1080.jpg&rf=LaDigue_1920x1080.jpg&pid=hp',
      },
      formInline: {
        email: '',
        password: '',
      },
      login_loading: false,
      ruleInline: {
        email: [
          {required: true, message: 'Enter your email', trigger: 'blur'},
          { validator: emailFormatValid, trigger: 'blur' }
        ],
        password: [
          {required: true, message: 'Enter your password', trigger: 'blur'},
          {type: 'string', min: 6, message: 'Password lengh must be over 6 numbers including letters', trigger: 'blur'}
        ]
      },
      brand_name: 'Day Log',
      login_p_l: 0,
      login_p_t: 0,
    };
  },
  methods: {
    handleSubmit(name) {
      this.login_loading = true;
      let param = {
        email: this.formInline.email,
        password: this.md5(this.formInline.password),
      };

      this.$refs[name].validate((valid) => {
        if (valid) {
          apiLogin(param).then(res => {
            if (res.data.err_no == 0) {
              localStorage.setItem('api_token', 'Bearer ' + res.data.results.token);
              localStorage.setItem('is_login', 1);
              loginUtil.setExpireTime();
              //跳转
              location.href = "/";
            } else {
              this.$message({
                message: res.data.err_msg,
                type: 'error'
              });
              this.login_loading = false;
            }
          }).catch(error => {
            this.$message({
              message: "It's a error message",
              type: 'error'
            });
            this.login_loading = false;
          });
        } else {
          this.login_loading = false;
        }
      });
    },
    enterToLogin(name) {
      this.handleSubmit(name);
    },
    checkLoginStatus() {
      let api_token = localStorage.getItem('api_token');
      let is_login = localStorage.getItem('is_login');
      if (api_token != undefined && is_login != 0) {
        //跳转
        location.href = "/";
      }
    },
  },
  created() {
    this.login_p_l = localStorage.getItem('login_p_l');
    this.login_p_t = localStorage.getItem('login_p_t');
  },
  mounted() {
    this.checkLoginStatus();
  }
};
</script>

<style scoped>
.login {
  min-height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.login-info {
  position: absolute;
  width: 380px;
  height: 470px;
  top: calc(50% - 235px);
  left: calc(50% - 190px);
  cursor: pointer;
}

.bg-desc {
  position: absolute;
  background: rgba(0, 0, 0, .4);
  margin-bottom: 1rem;
  bottom: 0;
  right: 1.5rem;
  color: #fff;
  padding: 6px 10px;
  border-radius: 6px;
}

.submit-button {
  width: 100%;
}

.login_title {
  text-align: left;
  color: #909399;
}

.forgot, .forgot a {
  color: #909399;
}

.mgl60 {
  margin-left: 60px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-80 {
  margin-top: 80px;
}

.el-form-item {
  margin-bottom: 40px;
}
</style>
